import {NextUIProvider} from '@nextui-org/react';

function App() {
  return (
    <NextUIProvider>
      <div className='w-[100vw] h-[100vh] bg-background relative overflow-hidden'>
        <video className='w-full h-full bg-black object-cover absolute' muted autoPlay loop>
          <source src='/video.mp4' type='video/mp4' muted autoPlay loop />
        </video>
        <div className='absolute w-full h-full bg-black/30 flex flex-col items-center justify-center text-white gap-4'>
          <p className='font-bold text-5xl'>PartyApp</p>
          <p className='font-medium text-2xl'>Hamarosan, ez nagyott fog szólni.</p>
        </div>
      </div>
    </NextUIProvider>
  );
}

export default App;
